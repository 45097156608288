import styles from './styles.module.css'
import {useMemo} from 'react';

import avEmpty from './avatars/empty.png';
import av1 from './avatars/1.jpg';
import av2 from './avatars/2.jpg';
import av3 from './avatars/3.jpg';
import av4 from './avatars/4.jpg';
import av5 from './avatars/5.jpg';
import av6 from './avatars/6.jpg';

export type ProfileData = {
  id: string,
  name: string,
  avatar?: string,
};

function getAvatar(avatar: string | undefined) {
  // no avatar
  if(!avatar)
    return avEmpty;

  // default avatar
  switch (avatar) {
    case '1': return av1;
    case '2': return av2;
    case '3': return av3;
    case '4': return av4;
    case '5': return av5;
    case '6': return av6;
  }

  // real avatar
  return avatar;
}

type PlayerSearchResultProps = {
  data: ProfileData;
}

export function PlayerSearchResult({data}: PlayerSearchResultProps) {

  const avatar = useMemo(() => getAvatar(data.avatar), [data]);

  return (
    <div className={styles.container}>
      <img className={styles.avatar} src={avatar} alt={data.name}/>
      <div className={styles.name}>{data.name}</div>
    </div>
  );
}

type PlayerResultProps = {
  data: ProfileData;
  resetPlayer: () => void,
}

export function PlayerSection({data, resetPlayer}: PlayerResultProps) {

  const avatar = useMemo(() => getAvatar(data.avatar), [data]);

  return (
    <div className={styles.user_container}>
      <div className={styles.similar_container}>
        <img className={styles.similar_avatar} src={avatar} alt={data.name}/>
        <div className={styles.similar_name}>{data.name}</div>
        <div className={styles.check_icon}></div>
      </div>
      <div className={styles.edit_user} onClick={resetPlayer}>Ред.</div>
    </div>
  );
}
