import React, {useEffect, useState} from 'react';
import axios from 'axios';

import styles from './chooseProduct.module.css';
import ChipsProduct, {ChipsProductData} from './ChipsProduct';
import {ApiResponse} from '../../Api';
import Spinner from '../../components/Spinner';
import ChestProduct, {ChestProductData} from './ChestProduct';
import {Product} from './index';
import {ProfileData} from '../../components/PlayerSearchResult';
import OfferProduct, {OfferProductData} from '../../components/OfferProduct/OfferProduct';
import pack from '../../components/OfferProduct/pack.png'

export enum ProductType {
  CHIPS = 'chips',
  CHEST = 'chest',
  OFFER = 'offer'
}

type ProductsData = {
  [ProductType.CHIPS]: ChipsProductData[],
  [ProductType.CHEST]: ChestProductData[],
  [ProductType.OFFER]: OfferProductData[],
};

type Props = {
  user: ProfileData,
  setCurrentStep: (step: number) => void,
  onProductChange: (product: Product) => void,
};

export default function ChooseProduct({user, setCurrentStep, onProductChange}: Props) {

  const [isLoadingProducts, setIsLoadingProducts] = useState(false);
  const [chipsProducts, setChipsProducts] = useState<ChipsProductData[]>([]);
  const [chestProducts, setChestProducts] = useState<ChestProductData[]>([]);
  const [offerProducts, setOfferProducts] = useState<OfferProductData[]>([]);

  const [isLoadingOffer, setLoadingOffer] = useState(false);
  const [activeOffer, setActiveOffer] = useState<{id: string, title: string, amount: number, price: number, expiryDate: number} | null>(null);
  
  // при инициализации компонента грузим продукты
  useEffect(() => {

    (async () => {

      setIsLoadingProducts(true);

      try {
        const response = await axios.get('api/products?clientPlatform=web');
        const data = response.data as ApiResponse<ProductsData>;

        if(!data.success)
          throw new Error(data.reason);

        setChipsProducts(data.data[ProductType.CHIPS]);
        setChestProducts(data.data[ProductType.CHEST]);
        setOfferProducts(data.data[ProductType.OFFER]);
      } catch (e) {
        setChipsProducts([]);
        setChestProducts([]);
        setOfferProducts([]);
        // console.error(e);
      } finally {
        setIsLoadingProducts(false);
      }
    })();
  }, []);

  useEffect(() => {
    
    (async () => {
      
      // TODO called twice useEffect
      //  first - default set null
      //  second - update "offerProducts"
      if (!offerProducts.length)
        return;
      
      // после загрузки продуктов, пытаемся найти офферов по конкретному игроку
      setLoadingOffer(true);

      try {
        const response = await axios.get(`api/offer?userId=${user.id}`);
        const data = response.data;

        if(!data.success)
          throw new Error(data.reason);

        // на случай если активный оффер отсутствуют
        if(!data.data)
          throw new Error;

        const offer = (() => {
          let result;

          Object.entries(offerProducts).forEach(([key, val]) => {
            if (val.id !== data.data.id)
              return;

            result = val;
          });

          return result;
        })() as OfferProductData | undefined;

        if(!offer)
          throw new Error(`offer ${data.id} is not defined`);
        
        setActiveOffer({...data.data, ...offer})
      } catch (e) {
        setActiveOffer(null);
        // console.error(e);
      } finally {
        setLoadingOffer(false);
      }
      
    })();
  }, [offerProducts]);
  
  function onChooseClick(type: ProductType, id: string, price: number, amount: number, title: string, image: string) {
    onProductChange({type, id, price, amount, title, image});
    // после выбора продукта отправляем на шаг оплаты
    setCurrentStep(2);
  }

  return (
    <>
    {
      isLoadingOffer
        ? (
          <div className={styles.spinner_container}>
            <Spinner/>
          </div>
        )
        : (
          <>
            {
              activeOffer && (
                <>
                  <h1 className={styles.color}>Специальное предложение</h1>
                  <OfferProduct
                    product={{id: activeOffer.id, title: activeOffer.title, price: activeOffer.price}}
                    key={activeOffer.id}
                    amount = {activeOffer.amount}
                    expiryDate={activeOffer.expiryDate}
                    onChooseClick={() => onChooseClick(ProductType.OFFER, activeOffer.id, activeOffer.price, activeOffer.amount, activeOffer.title, pack)}
                  />
                </>
              )}
          </>
        )
      }
      {
        isLoadingProducts
          ? (
            <div className={styles.spinner_container}>
              <Spinner/>
            </div>
          )
          : (
            <>
              <h1 className={styles.color}>Фишки</h1>
              <div className={styles.products}>
                {chipsProducts.map(product => <ChipsProduct product={product} key={product.id} onChooseClick={() => onChooseClick(ProductType.CHIPS, product.id, product.price, product.amount, product.title, product.image)}/>)}
              </div>
              <h1 className={styles.color}>Сундуки</h1>
              <div className={styles.products}>
                {chestProducts.map(product => <ChestProduct product={product} key={product.id} onChooseClick={() => onChooseClick(ProductType.CHEST, product.id, product.price, product.detailAmount, product.title, product.image)}/>)}
              </div>
            </>
          )
      }
    </>
  )
}