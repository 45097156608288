// фактически переопределил компонент ../components/ChestProduct
// TODO если старая реализация ChestProduct не будет использоваться, то можно спокойно выпиливать

import styles from './chestProduct.module.css';

export enum DetailKind {
  RANDOM = 'random',
  UNIQUE = 'unique',
}

export type ChestProductData = {
  id: string,
  price: number,
  title: string,
  image: string,
  color: number,
  detailKind: DetailKind,
  detailAmount: number,
}

type Props = {
  product: ChestProductData,
  onChooseClick: Function,
};

function detailDescription(kind: DetailKind): string {
  switch (kind) {
    case DetailKind.RANDOM: return 'случайных';
    case DetailKind.UNIQUE: return 'уникальных';
    default:
      console.warn(`Unknown detail kind: ${kind}`);
      return 'неизвестных'
  }
}

function formatLongNumber(price: number): string {
  return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

export default function ChestProduct({product, onChooseClick}: Props) {

  const chooseButtonClasses = [
    styles.choose_buy,
  ].join(' ');

  return (
    <div className={styles.card}>
      <p className={styles.title}>{product.title}</p>
      <img src={product.image} width='139px' height='76px' alt={product.title}/>
      <div className={styles.value}>
        x{product.detailAmount} {detailDescription(product.detailKind)} деталей
      </div>
      <div className={styles.price_info}> Цена</div>
      <div className={styles.price}>{formatLongNumber(product.price)} ₽</div>
      <button className={chooseButtonClasses} onClick={() => onChooseClick()}> Выбрать</button>
    </div>
  );
}