import styles from './styles.module.css';

export enum StickerKind {
  BEST_PRICE = 'best_price',
  POPULAR = 'popular',
  SALE = 'sale',
}

type Props = {
  kind: StickerKind;
}

export default function Sticker({kind}: Props) {

  switch (kind) {

    case StickerKind.POPULAR: return (
      <div className={styles.container}>
        <div className={styles.label_red}>Все берут</div>
        <div className={styles.image_red}/>
      </div>
    );

    case StickerKind.BEST_PRICE: return (
      <div className={styles.container}>
        <div className={styles.label_violet}>Лучшая цена</div>
        <div className={styles.image_violet}/>
      </div>
    );

    case StickerKind.SALE: return (
      <div className={styles.container}>
        <div className={styles.label_yellow }>Распродажа</div>
        <div className={styles.image_yellow }/>
      </div>
    );
  }

  throw new Error(`Invalid sticker kind: ${kind}`);
}