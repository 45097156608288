// фактически переопределил компонент ../components/ChipsProduct
// TODO если старая реализация ChipsProduct не будет использоваться, то можно спокойно выпиливать

import styles from './chipsProduct.module.css';
import {useMemo} from 'react';
import icon from '../../components/ChipsProduct/icon.png';
import Sticker, {StickerKind} from '../../components/Sticker';

export type ChipsProductData = {
  id: string,
  price: number,
  originalPrice: any,
  title: string,
  image: string,
  isBestPrice: boolean,
  isPopular: boolean,
  isSale: boolean,
  amount: number,
}

type Props = {
  product: ChipsProductData,
  onChooseClick: Function,
}

function formatLongNumber(price: number): string {
  return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

export default function ChipsProduct({product, onChooseClick}: Props) {

  const stickerKind = useMemo<StickerKind | null>(() => {

    if (product.isBestPrice)
      return StickerKind.BEST_PRICE;

    if (product.isPopular)
      return StickerKind.POPULAR;

    if (product.isSale)
      return StickerKind.SALE;

    return null;

  }, [product])

  const chooseButtonClasses = [
    styles.choose_buy,
  ].join(' ');

  function renderSection() {

    if (product.isSale) {
      return (
        <>
          <div className={`${styles.price_info} ${styles.sale}`}> Цена</div>
          <div className={`${styles.price} ${styles.sale}`}>{formatLongNumber(product.price)} ₽
            {product.originalPrice &&
              <div className={`${styles.old_price} ${styles.sale}`}>{product.originalPrice}</div>}
          </div>
        </>
      )
    }

    return (
      <>
        <div className={styles.price_info}> Цена</div>
        <div className={styles.price}>{formatLongNumber(product.price)} ₽</div>
      </>
    )
  }

  return (
    <>
      <div className={`${styles.card} ${product.isSale ? styles.sale : null}`}>
        {stickerKind && <div className={styles.sticker}><Sticker kind={stickerKind}/></div>}
        <img src={product.image} width="139px" height="72px" alt={product.title}/>
        <div className={styles.value}>
          <img className={styles.icon} src={icon} alt={product.title}/> {formatLongNumber(product.amount)}
        </div>
        {renderSection()}
        <button className={chooseButtonClasses} onClick={() => onChooseClick()}>Выбрать</button>
      </div>
    </>
  );
}