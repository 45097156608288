import styles from '../PlayerSearchError/styles.module.css';

type Props = {
  message: string,
};

export default function PlayerSearchError({message}: Props) {
  return (
    <div className={styles.container}>
      {message}
      <div className={styles.icon}></div>
    </div>
  );
}