import axios from 'axios';
import {useEffect, useState} from 'react';

import styles from './choosePaymentMethods.module.css';
import {ProfileData} from '../../components/PlayerSearchResult';
import {Product} from './index';
import {ApiResponse} from '../../Api';
import OverlaySpinner from '../../components/Spinner/overlay';

type Props = {
  user: ProfileData;
  product: Product;
  email: string;
}

const paymentMethods = [
  {
    title: "Банковская карта (МИР, Mastercard, Visa, Maestro, UnionPay, JCB)",
    type: "bank_card",
    style: styles.bank_card,
  },
  {
    title: "SberPay",
    type: "sberbank",
    style: styles.sber,
  },
  {
    title: "Tinkoff Pay",
    type: 'tinkoff_bank',
    style: styles.tinkoff,
  },
  {
    title: "Альфа-Клик",
    type: "alfabank",
    style: styles.alpha,
  },
  {
    title: "ЮMoney",
    type: "yoo_money",
    style: styles.you_money,
  },
  {
    title: "QIWI",
    type: "qiwi",
    style: styles.qiwi,
  },
];

type PurchaseData = {
  paymentId: string,
  confirmationUrl: string,
};

type GeoData = {
  country: string;
};

export default function ChoosePaymentMethod({user, product, email}: Props) {

  const [isLoadingPurchase, setIsLoadingPurchase] = useState(false);
  const [isForeignBankCard, setForeignBankCard] = useState(false);
  const [isLoadingForeignBankCard, setLoadingForeignBankCard] = useState(false);
  const [isShowForeignBankCard, setShowForeignBankCard] = useState(false);
  const [isClickForeignBankCard, setClickForeignBankCard] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    (async () => {

      setLoadingForeignBankCard(true);

      let response;

      try {
          response = await axios.get('api/geo', {timeout: 5000});
      } catch (e) {
        // TODO на случай если публичный айпи не удалось получить за отведенное время
        //  Показываем две кассы для оплаты
        setForeignBankCard(true);
        setLoadingForeignBankCard(false);
        return;
      }

      const data = response.data as ApiResponse<GeoData>;

      if (!data.success){
        setLoadingForeignBankCard(false);
        return;
      }

      const {country} = data.data;

      if (!country){
        setLoadingForeignBankCard(false);
        return;
      }

      setForeignBankCard(country !== 'RU');
      setLoadingForeignBankCard(false);
    })();
  }, []);

  async function onBuyClick(type: string) {
    if (type === 'bank_card' && isForeignBankCard) {
      setShowForeignBankCard(true);
      return;
    }

    await purchase(type);
  }

  async function purchase(type: string) {
    let response;

    setIsLoadingPurchase(true);

    try {
      response = await axios.post('api/purchase', {
        userId: user.id,
        productType: product.type,
        productId: product.id,
        email,
        paymentType: type,
      });
    } catch (e) {
      setIsLoadingPurchase(false);
      setError(true);
      return;
    }

    const data = response.data as ApiResponse<PurchaseData>;

    if (!data.success) {
      setError(true);
      setIsLoadingPurchase(false);
      return;
    }

    const {confirmationUrl} = data.data;

    window.location.href = confirmationUrl;

    setIsLoadingPurchase(false);
  }

  return (
    <>
      {
        (isLoadingPurchase || isLoadingForeignBankCard) && <OverlaySpinner text={'Загружаем форму оплаты..'}/>
      }
      <div className={styles.title}>
        <h1>Выберите удобный способ оплаты</h1></div>
        {error && <div className={styles.error}>
          Ошибка сервера, попробуйте выполнить операцию позже или напишите нам в <a target="_blank" href='https://vk.com/durakonline3d'>техническую поддержку</a>
        </div>}
        <div className={styles.payment_container}>
          <div className={styles.payments}>
            {
              !isShowForeignBankCard && (
                paymentMethods.map(({title, type, style}) => {
                  return (
                    <div onClick={() => onBuyClick(type)} className={styles.pay_card}>
                      <div className={`${styles.icon} ${style}`}></div>
                      <span>{title}</span>
                    </div>
                  )
                })
              )
            }
            {
              isShowForeignBankCard && (
                <div className={styles.btn_container}>
                  <div className={`${styles.pay_card} ${styles.btn}`} onClick={async () => await purchase('bank_card')}>Российская карта</div>
                  <div className={`${styles.pay_card} ${styles.btn} ${isClickForeignBankCard ? styles.lock : ''}`} onClick={() => setClickForeignBankCard(true)}>
                    Иностранная карта
                    {isClickForeignBankCard && <div className={`${styles.lock_info}`}>Временно недоступна</div>}
                  </div>
                  <div className={styles.back} onClick={() => setShowForeignBankCard(false)}>
                    <img src="back_arrow.svg"/>
                    <span>Другой способ оплаты</span>
                  </div>
                </div>
              )
            }
          </div>
      </div>
    </>
  )
}
