import React, { useState } from 'react';

import styles from './styles.module.css';
import SearchPlayer from './SearchPlayer';
import {PlayerSection, ProfileData} from '../../components/PlayerSearchResult';
import ChooseProduct, {ProductType} from './ChooseProduct';
import FinalOrder from './FinalOrder';
import {ChestProductData} from './ChestProduct';
import {ChipsProductData} from './ChipsProduct';
import ChoosePaymentMethod from './ChoosePaymentMethod';

// фактически подвязываемся на строгую последовательность шагов
// 0 шаг - поиск игрока
// 1 шаг - выбор продукта
// 2 шаг - просмотр заказа + указание почты
// 3 шаг - эмулируем шаг, где выбираем способ оплаты + с последующей оплатой на стороне касс

type Step = {
  title: string,
}

type Steps = Step[];

const steps: Steps = [
  {
    title: 'Выбор игрока',
  },
  {
    title: 'Выбор набора фишек',
  },
  {
    title: 'Покупка',
  },
];

export type Product = {
  type: ProductType,
  id: string,
  price: number,
  amount: ChipsProductData['amount'] | ChestProductData['detailAmount'],
  title: string,
  image: string,
};

export function Gifts() {

  const [currentStep, setCurrentStep] = useState(0);
  const [targetUser, setTargetUser] = useState<ProfileData | null>(null);
  const [highlight, setHighlight] = useState(false);
  const [targetProduct, setTargetProduct] = useState<Product | null>(null);
  const [targetEmail, setTargetEmail] = useState('');

  async function jumpStep(index: number) {

    // не переходим на текущий шаг
    if(index === currentStep)
      return;

    // нельзя перейти больше / меньше чем на 1 шаг
    if(index - currentStep > 1 || currentStep - index > 1)
      return;

    // при переходе на 0 шаг чистим профиль
    if(index === 0)
      setTargetUser(null);

    // нельзя перейти на 1 шаг, без выбора игрока
    if(index === 1 && !targetUser){
      setHighlight(true);
      return;
    }

    // при переходе на 1 шаг чистим продукт
    if(index === 1)
      setTargetProduct(null);

    // нельзя перейти на 2 шаг, без выбора продукта
    if(index === 2 && !targetProduct)
      return;

    setCurrentStep(index);
  }

  function renderTitleBlocks(step: Step, index: number) {

    const classes = (() => {

      if (currentStep === index) {
        return styles.title_item_active;
      }

      if (currentStep > index) {
        return styles.title_item_completed;
      }

      return styles.title_item;
    })();

    return (
      <div key={index} className={classes} onClick={async () => await jumpStep(index)}>
        {step.title}
      </div>
    );
  }

  function renderNumberBlocks(step: Step, index: number) {

    const classes = (() => {

      if (currentStep === index) {
        return styles.number_border_active;
      }

      if (currentStep > index) {
        return styles.number_border_completed;
      }

      return styles.number_border;
    })();

    
    // TODO Warning: Each child in a list should have a unique "key" prop.
    //  требуется обернуть в тэг для того что бы добавить "key"
    return (
      <>
        <div className={classes}>{index + 1}</div>
        {steps.length !== index + 1 &&
          <div className={styles.number_arrow}>
            <div className={styles.arrow_line}></div>
            <div className={styles.img_arrow}></div>
          </div>
        }
      </>
    );
  }

  function renderStepNavigation() {

    return (
      <div className={styles.purchase_stages_container}>
        <div className={styles.purchase_stages}>
          <div className={styles.numbers_container}>
            <div className={styles.fade_container}></div>
            {steps.map((step, index) => renderNumberBlocks(step, index))}
            <div className={styles.fade_container}></div>
          </div>
          <div className={styles.titles_container}>
            {steps.map((step, index) => renderTitleBlocks(step, index))}
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      { (currentStep === 0 || currentStep === 1 || currentStep === 2) && renderStepNavigation() }

        {currentStep === 0 &&
          <div className={styles.selectform}>
            <SearchPlayer onPlayerChange={setTargetUser} highlight={highlight} onHighlightInput={() => setHighlight(false)} onStepChange={setCurrentStep}/>
          </div>
          }

        {(currentStep === 1 || currentStep === 2) && targetUser &&
          <div className={styles.selectform}>
            <PlayerSection data={targetUser} resetPlayer={() => {
              setTargetUser(null);
              setTargetProduct(null);
              setCurrentStep(0);
            }}/>
          </div>
        }

        {currentStep === 1 && targetUser &&
          <ChooseProduct user={targetUser} setCurrentStep={setCurrentStep} onProductChange={setTargetProduct}/>
        }

        {currentStep === 2 && targetUser && targetProduct &&
          <FinalOrder product={targetProduct} email={targetEmail} setEmail={setTargetEmail} setCurrentStep={setCurrentStep}/>
        }

        {currentStep === 3 && targetUser && targetProduct && targetEmail &&
          <ChoosePaymentMethod user={targetUser} product={targetProduct} email={targetEmail}/>
        }
    </>
  );
}