import React from 'react';

import styles from './styles.module.css';

import NavButton from '../NavButton';
import NavLink from '../NavLink';

import { Logo } from '../Logo';

export default function Header() {

  return (
      <div className={styles.header}>
        <Logo />
        <div className={styles.right}>
          <input id={styles.menu_toggle} type="checkbox" />
          <label className={styles.menu_btn} htmlFor={styles.menu_toggle} />
          <div className={styles.navlist}>
            <NavLink href={'/durak.apk'}>
              <img width="28" src='download.svg'/>
              Скачать игру
            </NavLink>
          </div>
          <NavButton to="/gifts">Купить фишки</NavButton>
        </div>
      </div>
   
  );
}