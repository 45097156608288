import styles from './styles.module.css';

type Props = {
  text: string,
}

// TODO можно закинуть было в index.tsx отдельным экспортом без указание default
export default function OverlaySpinner({text}: Props) {
  return (
    <div className={styles.overlay}>
      <div className={styles.spinner}/>
      {text}
    </div>
  );
}