import React from 'react';

import {useState} from 'react';

import styleGallery from './gallery.module.css';
import styleModal from './modal.module.css';

export type Image = {
  preview: string,
  origin: string
};

type Props = {
  images: Image[],
};

export default function ImageGallery({images}: Props) {

  const [isShowModal, setIsShowModal] = useState(false);
  const [currentModalImage, setCurrentModalImage] = useState(0);

  function touch(index: number) {
    setIsShowModal(true);
    setCurrentModalImage(index);
  }

  function setPrevModalImage() {
    setCurrentModalImage(x => (images.length + x - 1) % images.length);
  }

  function setNextModalImage() {
    setCurrentModalImage(x => (x + 1) % images.length);
  }

  return (
    <>

      <div className={styleModal.modal} style={{display: isShowModal ? '' : 'none'}}>

        <div className={styleModal.modal_body}>

          <button className={`${styleModal.arrow} ${styleModal.left}`} onClick={setPrevModalImage}/>
          <button className={`${styleModal.arrow} ${styleModal.right}`} onClick={setNextModalImage}/>

          {
            images.map((image, index) => (
              <img
                style={{display: currentModalImage === index ? 'block' : 'none'}}
                key={index}
                className={styleModal.modal_item}
                src={image.origin}
                alt={`Скриншот ${index + 1}`}
              />
            ))
          }

        </div>

        <div className={styleModal.modal_container} onClick={() => setIsShowModal(false)}/>

      </div>

      <div id={styleGallery.image_gallery}>
        {
          images.map((image, index) => (
            <img
              key={index}
              onClick={() => touch(index)}
              className={styleGallery.image_gallery_item}
              src={image.preview}
              width='142px'
              height='257px'
              alt={`Скриншот ${index + 1}`}
            />
          ))
        }
      </div>

    </>

  );
}