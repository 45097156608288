import styles from './offerProduct.module.css';
import Sticker, {StickerKind} from '../Sticker';

export type OfferProductData = {
  id: string,
  title: string,
  price: number,
}

type Props = {
  amount: number,
  expiryDate: number,
  product: OfferProductData,
  onChooseClick: Function,
}

function formatDate(value: number) {
  const date = new Date(value);
  return ('0' + (date).getDate()).slice(-2) + '/' + ('0' + (date.getMonth() + 1)).slice(-2) + '/' + date.getFullYear() + ' ' + ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2);
}

export default function OfferProduct({amount, expiryDate, onChooseClick, product}: Props) {

  return (
   <div className={styles.offer_greed}>
    <div className={styles.offer_position}>
      <div className={styles.offer_products}>
        <div className={styles.card_offer}>
          <div className={styles.pack_info}>
            
            <div className={styles.pack_icon}>
            <div className={styles.sale_bg}></div>
              {/*<div className={styles.lable_icon}>*/}
              {/*  <div className={styles.lable_sale}>-75%</div>*/}
              {/*  <div className={styles.lable_text}>Выгода</div>*/}
              {/*</div>*/}
            </div>
            <div className={styles.value}>
              <div className={styles.icon_offer}/>
              {amount}</div>
          </div>
          <div className={styles.pack_price}>
            <div className={styles.sticker}><Sticker kind={StickerKind.BEST_PRICE}/></div>
            <div className={styles.price_info}> Цена</div>
            <div className={styles.price}>{Math.round(0.00178 * amount)} ₽</div>
            <div className={styles.price_active}>{product.price} ₽</div>
            <button className={styles.btn_choose} onClick={() => onChooseClick()}>Выбрать</button>
          </div>
        </div>
      </div>
      <div className={styles.timer_container}>
        Специальное предложение действует до:
        <span className={styles.timer}>{ formatDate(expiryDate) }</span>
      </div>
    </div>
   </div>
  );
}