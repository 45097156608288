import React from 'react';
import {Routes, Route, Navigate} from 'react-router-dom';

import './layout.css';
import './App.css';

import Header from './components/Header';
import Footer from './components/Footer';

import Game from './pages/Game';
import {Gifts} from './pages/Gifts';
import Download from './pages/Download';
import Terms from './pages/Terms';
import Maintenance from './pages/Maintenance';

function App() {

  return (
    <div className="layout-wrapper">

      <header>
        <Header/>
      </header>

      <main>
        <Routes>
          {/*<Route path="/game" element={<Download/>}/>*/}
          <Route path="/gifts" element={<Gifts/>}/>
          {/*<Route path="/download" element={<Download/>}/>*/}
          <Route path="/terms" element={<Terms/>}/>
          <Route path="*" element={<Navigate to="/install" replace />}/>
          <Route path="/install" element={<Download/>}/>
        </Routes>
      </main>

      <footer>
        <Footer/>
      </footer>

    </div>
  );
}

export default App;
