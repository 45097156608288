import {Link, To} from 'react-router-dom';
import {ReactNode} from 'react';

import styles from './styles.module.css';

type LocalLinkProps = {
  children: ReactNode,
  to: To,
};

type ExternalLinkProps = {
  children: ReactNode,
  href: string,
}

type Props = LocalLinkProps | ExternalLinkProps;

function isLocal(props: Props): props is LocalLinkProps {
  return 'to' in props;
}

function isExternal(props: Props): props is ExternalLinkProps {
  return 'href' in props;
}

export default function NavLink(props: Props) {

  if(isLocal(props))
    return <Link to={props.to} className={styles.navlink}>{props.children}</Link>

  if(isExternal(props))
    return <a href={props.href} className={styles.navlink}>{props.children}</a>

  throw new Error('Invalid props');
}