import React from 'react';

import styles from './styles.module.css';

import NavLink from '../NavLink';

export default function Footer() {
  return (
    <div className={styles.footer}>
      <p>© 2020 - {(new Date()).getFullYear()} ООО "Бэстингеймз" ИНН 7802881892</p>
      <div className={styles.navlist}>
        <NavLink href="https://docs.google.com/document/d/1mQTDdRFBO58sG64kCrdm3axa0ZPxV49L">Политика конфиденциальности</NavLink>
        <NavLink href="https://docs.google.com/document/d/1AgBJct1K7VrgquWGLlDnRivKCnlSzFFd">Условия оплаты</NavLink>
        {/*<NavLink to="terms">Оферта</NavLink>*/}
        <NavLink href="https://vk.com/im?sel=-140415383">Поддержка</NavLink>
      </div>
    </div>
  );
}