import React from 'react';
import {useState} from 'react';

import styles from './styles.module.css';

type Card = {
  title: string,
  body: string,
};

export type Cards = Card[];

type Props = {
  title: string,
  cards: Cards,
};

export default function Collapse({title, cards}: Props) {

  const [isShowCards, setShowCards] = useState(false);
  
  function renderCards(card: Card, index: number) {
    return (
      <div className={styles.card} key={index}>
        <h1> {card.title}:</h1>
        <text className={styles.instruction_text}>{card.body}</text>
      </div>
    )
  }

  return (
    <div className={styles.collapse}>
      <div className={styles.collapse_header} onClick={() => setShowCards(!isShowCards)}>
        <div className={styles.collapse_title}>
          <div className={styles.icon_question}/>
          <text className={styles.collapse_text}>{title}</text>
        </div>
        <div className={isShowCards ? `${styles.btn_open_arrow} ${styles.active}` : `${styles.btn_open_arrow}`}/>
      </div>
      <div className={isShowCards ? `${styles.collapse_body} ${styles.active}` : `${styles.collapse_body}`}>
        {cards.map((card, index) => renderCards(card, index))}
      </div>
    </div>
  );
}