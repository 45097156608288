// фактически переопределил компонент ../components/PlayerSelection
// TODO если старая реализация PlayerSelection не будет использоваться, то можно спокойно выпиливать

import {useEffect, useState} from 'react';
import axios from 'axios';

import styles from './searchPlayer.module.css';
import PlayerSearchForm from '../../components/PlayerSearchForm';
import {ProfileData} from '../../components/PlayerSearchResult';
import Spinner from '../../components/Spinner';
import {ApiResponse} from '../../Api';
import PlayerSearchError from '../../components/PlayerSearchError';

type Props = {
  onPlayerChange: (profile: ProfileData | null) => void,
  highlight: boolean;
  onHighlightInput: () => void,
  onStepChange: (step: number) => void,
};

export default function SearchPlayer({onPlayerChange, highlight, onHighlightInput, onStepChange}: Props) {

  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [profile, setProfile] = useState<ProfileData | null>(null);

  // при изменении profile выстреливаем
  useEffect(() => onPlayerChange(profile ?? null), [profile, onPlayerChange]);

  async function findPlayer(term: string) {

    setProfile(null);
    setIsLoading(true);

    let response;

    try {
      response = await axios.get(`api/search?term=${term}`);
    } catch (e) {
      setMessage('Ошибка загрузки');
      console.error(e);
      return;
    } finally {
      setIsLoading(false);
    }

    const data = response.data as ApiResponse<ProfileData>;

    if(!data.success) {
      setMessage('Ошибка сервера');
      console.error(data.reason);
      return;
    }

    if(!data.data) {
      setMessage('Игрок не найден');
      return;
    }

    setProfile(data.data);
    onStepChange(1);
  }

  function interceptHighlightInput() {
    setMessage('');
    onHighlightInput();
  }

  function renderForm() {
    return (
      <>
        <PlayerSearchForm onSubmit={term => findPlayer(term)} disabled={isLoading} highlight={!isLoading && highlight} onHighlightInput={interceptHighlightInput}/>
      </>
    );
  }

  function renderResult() {

    if(highlight)
      return <PlayerSearchError message='обязательное поле'/>

    if (isLoading)
      return (
        <div className={styles.spinner_container}>
          <Spinner/>
        </div>
      );

    if(message)
      return <PlayerSearchError message={message}/>;

    return (
      <>
        Чтобы перейти к выбору набора фишек, найдите игрока по id
      </>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>Кому отправить набор фишек?</div>
      <div className={styles.content}>
        <div className={styles.item}>
          {renderForm()}
        </div>
        <div className={styles.item}>
          {renderResult()}
        </div>
      </div>
    </div>
  );
}