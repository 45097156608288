import {Link, To} from 'react-router-dom';
import {ReactNode} from 'react';

import styles from './styles.module.css';

type Props = {
  to: To,
  children: ReactNode,
};

export default function NavButton({to, children}: Props) {
  return (
    <Link to={to} className={styles.navbutton}>{children}</Link>
  );
}